import React, { Component } from 'react'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'


import { connect } from 'react-redux';
import { postAboutMeText, getAboutMeText, getYogaClassesText, postYogaClassesText } from '../redux/actions/dataActions'
import SunEditor from 'suneditor-react';

import FeedbackComponent from '../components/feedback/feedbackComponent'


export class SunEditorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editToolBar: {
                "showPathLabel": false,
                "mode": "classic",
                "katex": "window.katex",
                "buttonList": [
                    [
                        "undo",
                        "redo",
                        "font",
                        "fontSize",
                        "formatBlock",
                        "paragraphStyle",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "textStyle",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "table",
                        "link",
                        "image",
                        "showBlocks",
                        "codeView",
                        "preview",
                        "template"
                    ]
                ]
            },
            viewToolBar: {
                "showPathLabel": false,
                "resizingBar": false
            },
            newContent: "",
            type: this.props.type
        }
        this.cancel = null;

    }
    componentDidMount() {
        if (this.state.type === 1) {//retrieve about me
            this.props.getAboutMeText(this);
        }
        if (this.state.type === 2) {//get yoga classes
            this.props.getYogaClassesText(this);
        }
    }

    componentWillUnmount() {
        this.cancel();
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated) {
            this.setState({ authenticated: nextProps.authenticated });
        }
        if (nextProps.aboutMeText) {
            this.setState({ aboutMeText: nextProps.aboutMeText });
            localStorage.setItem("aboutMeText", this.state.aboutMeText);
        }
        if (nextProps.yogaClassesText) {
            this.setState({ yogaClassesText: nextProps.yogaClassesText });
            localStorage.setItem("yogaClassesText", this.state.yogaClassesText);
        }
    }

    onChange = (content) => {
        this.setState({
            newContent: content
        })
    }


    handleSubmit = () => {
        if (this.props.type === 1) {
            this.props.postAboutMeText(this.state.newContent);
        }
        if (this.props.type === 2) {
            this.props.postYogaClassesText(this.state.newContent);
        }
    }

    handleReturnText = (aboutMeText, yogaClassesText) => {
        if (this.props.type === 1) {
            return aboutMeText
        }
        if (this.props.type === 2) {
            return yogaClassesText
        }
    }

    render() {
        const authenticated = this.props.authenticated;
        const loading = this.props.loading;
        const aboutMeText = this.props.aboutMeText;
        const feedback = this.props.feedback.message;
        const yogaClassesText = this.props.yogaClassesText;
        const errors = this.props.errors;
        const type = this.state.type;
        const spinnerGetStyles = { margin: "10px" }
        console.log("SUNEDITOR", feedback);
        console.log("SUNEDITOR", errors);
        return (
            <div>
                {type === 1? <FeedbackComponent aboutMeType content={feedback}/> : <FeedbackComponent yogaClassesType content={feedback}/>}
                {authenticated ? <SunEditor setOptions={this.state.editToolBar} enabled={true} setContents={this.handleReturnText(aboutMeText, yogaClassesText)} onChange={this.onChange} /> : loading ? <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={spinnerGetStyles}
                /> : <SunEditor setOptions={this.state.viewToolBar} disable={true} enableToolbar={false} showToolbar={false} setContents={this.handleReturnText(aboutMeText, yogaClassesText)} />}

                {authenticated ? <Button style={{ margin: 10, float: "right" }} onClick={() => { this.handleSubmit() }} variant="outline-success">{loading ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> : undefined} Submit Changes</Button> : undefined}
                {errors ? <Alert variant="danger">{errors}</Alert> : undefined}
            
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    aboutMeText: state.data.aboutMeText,
    yogaClassesText: state.data.yogaClassesText,
    feedback: state.data.feedback,
    loading: state.UI.loading,
    errors: state.UI.errors,
    authenticated: state.user.authenticated
})

const mapActionsToProps = {
    postAboutMeText,
    getAboutMeText,
    getYogaClassesText,
    postYogaClassesText
}

export default connect(mapStateToProps, mapActionsToProps)(SunEditorComponent);
