import React from 'react'

import Alert from 'react-bootstrap/Alert'

 //PROPS : content, variant
const AlertComponent = (props) => {
    
    return (
        <div>
            <Alert variant={props.variant} style={{"margin" : "20px"}}>
                {props.content}
            </Alert>
        </div>
    )
}

export default AlertComponent
