//USER REDUCER TYPES
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
//UI REDUCER TYPES
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
//DATA REDUCER TYPES
export const POST_ABOUTMETEXT = "POST_ABOUTMETEXT";
export const POST_YOGACLASSESTEXT = "POST_YOGACLASSESTEXT";
export const GET_ABOUTMETEXT = "GET_ABOUTMETEXT"
export const GET_YOGACLASSESTEXT = "GET_YOGACLASSESTEXT"