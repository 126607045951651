import React, { Component } from 'react'
import NavBar from '../components/NavBar'
//bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//sections
import CarouselSection from '../sections/CarouselSection'
import AboutSection from '../sections/AboutSection'
import ContactSection from '../sections/ContactSection'
import YogaClassSection from '../sections/YogaClassSection'
import FooterSection from '../sections/FooterSection'
//axios
import axios from 'axios'
import StaticBanner from '../components/StaticBanner/StaticBanner'

export class home extends Component {
    constructor() {
        super();
        this.state = {
            below: true,
        }
        this.onScrollEvent = this.onScrollEvent.bind(this);
    }

    onScrollEvent = (event) => {
        var h = window.innerHeight;
        if (window.scrollY >= h - h / 32) {
            this.setState({ below: true });

        }
        else {
            this.setState({ below: false });
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ below: true })
        // window.addEventListener("scroll", this.onScrollEvent);
    }

    componentWillUnmount() {
        // window.removeEventListener("scroll", this.onScrollEvent);
    }


    render() {

        const containerStyle = { padding: "0px", margin: "0px" };
        const rowStyle = { paddingLeft: "5px", paddingRight: "5px", backgroundColor: "white", minHeight: "600px" };
        const outerDivStyle = { padding: "0px", margin: "0px", backgroundColor: "#26A69A", overflow: "hidden" };
        const footerStyle = { paddingLeft: "5px", paddingRight: "5px", backgroundColor: "#E0E0E0", height: "150px", overflow: "none" }
        const innerCol = { padding: "20px" };
        return (
            <>
                <NavBar below={this.state.below} setSticky={this.state.setSticky} />
                <div style={{ overflowX: "hidden" }}>
                    <Container fluid style={containerStyle}>
                        <Row id="homeSection" >
                            <Col xs={12}><StaticBanner /></Col>
                        </Row>
                        <Row id="aboutSection" style={rowStyle}>
                            <Col xs={0} lg={1} xl={2}></Col>
                            <Col xs={12} lg={10} xl={8} style={innerCol}><AboutSection /></Col>
                            <Col xs={0} lg={1} xl={2}></Col>
                        </Row>

                        <Row id="yogaSection" style={rowStyle}>

                            <Col xs={0} lg={1} xl={2}></Col>
                            <Col xs={12} lg={10} xl={8} style={innerCol}><YogaClassSection /></Col>
                            <Col xs={0} lg={1} xl={2}></Col>
                        </Row>
                        <Row id="contactSection" style={rowStyle}>
                            <Col xs={0} lg={1} xl={2}></Col>
                            <Col xs={12} lg={10} xl={8}><ContactSection /></Col>
                            <Col xs={0} lg={1} xl={2}></Col>
                        </Row>
                        <Row style={footerStyle}>
                            <Col xs={0} lg={1} xl={2}></Col>
                            <Col xs={12} lg={10} xl={8}><FooterSection /></Col>
                            <Col xs={0} lg={1} xl={2}></Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default home
