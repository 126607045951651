import { SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI, SET_UNAUTHENTICATED, SET_AUTHENTICATED, POST_ABOUTMETEXT, POST_YOGACLASSESTEXT, GET_YOGACLASSESTEXT, GET_ABOUTMETEXT } from '../types';
import axios from 'axios';

const initialState = {
    aboutMeText:  localStorage.getItem('aboutMeText') || "<h1>Loading, Please Wait</h1>",
    yogaClassesText :  localStorage.getItem('yogaClassesText') || "<h1>Loading, Please Wait</h1>",
    feedback: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ABOUTMETEXT:
            return {
                ...state,
                aboutMeText: action.payload
            }
        case GET_YOGACLASSESTEXT:
            return {
                ...state,
                yogaClassesText: action.payload
            }
        case POST_ABOUTMETEXT:
            return {
                ...state,
                feedback: action.payload
            }
        case POST_YOGACLASSESTEXT:
            return {
                ...state,
                feedback: action.payload
            }
        default:
            return state;
    }
}