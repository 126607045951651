import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'

import Title from '../../src/components/Title'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert'

import emailjs from 'emailjs-com';

export class ContactSection extends Component {
    constructor() {
        super()
        this.state = {
            firstname: '',
            surname: '',
            email: '',
            query: '',
            errors: {},
            loading: false,
            success: undefined
        }
        this.formSubmit = this.formSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        emailjs.init("user_aOsybIMKMqREWDYoYPLSJ")
    }


    


    formSubmit = (event) => {
        event.preventDefault();

        const { firstname, surname, email, query } = this.state;

        const localErrors = {}
        if (firstname === "" || firstname === undefined) {
            localErrors["firstname"] = "Please enter your Firstname"
        }
        if (email === "" || email === undefined) {
            localErrors["email"] = "Please enter your Email Address"
        }
        if (query === "" || query === undefined) {
            localErrors["query"] = "Please enter a Query"
        }
        this.setState({
            errors: localErrors
        })
        if (!this.isEmpty(localErrors)) {
            return
        }


        var template_params = {
            "reply_to": this.state.email,
            "from_name": `${firstname} ${surname}`,
            "to_name": "Pazzy",
            "message_html": this.state.query
        }

        var service_id = "gmail";
        var template_id = "template_05vkT1Qk";


        emailjs.send(service_id, template_id, template_params)
            .then(response => {
                this.setState({
                    success: true
                })
            }).catch(error => {
                //any errors that occur when sending the form
                this.setState({
                    success: false
                })
            });
    }

    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    handleChange = (event) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }

    render() {
        const conStyle = { margin: "10px 0px 40px 0px" };
        const formStyle = { margin: "20px 10px 10px 10px" };
        const alertStyle = { margin: "20px 10px 20px 10px" };
        return (
            <Container fluid style={conStyle}>
                <Title name="Contact Us" />
                <Form style={formStyle} onSubmit={this.formSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control name="firstname" onChange={this.handleChange} placeholder="Enter First Name" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSurName">
                            <Form.Label>Surname</Form.Label>

                            <Form.Control name="surname" onChange={this.handleChange} placeholder="Enter Surname" />

                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label>Email</Form.Label>

                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="email" onChange={this.handleChange} type="email" placeholder="Enter Email" />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>


                    <Form.Group controlId="formQuery">
                        <Form.Label>Query</Form.Label>
                        <Form.Control as="textarea" name="query" onChange={this.handleChange} placeholder="Enter your Query" rows="3" />
                    </Form.Group>
                    {this.state.success === undefined ? undefined : this.state.success ? <Alert style={alertStyle} variant="success">Query Submitted</Alert> : <Alert style={alertStyle} variant="danger">An Error has occured please try again later</Alert>}

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>


            </Container>
        )
    }
}

export default ContactSection
