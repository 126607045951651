import React, { Component } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'


import SunEditorComp from '../components/SunEditorComponent'
import Title from '../components/Title';

import axios from 'axios'

import { connect } from 'react-redux'

import Media from 'react-media'

import profileYogaPic from '../pics/carouselPictures/compressed/pazzyyogapic2.jpg'

export class AboutSection extends Component {
    constructor() {
        super();
        this.state = {
            aboutString: ''
        }
    }


    render() {
        const titleStyle = { textAlign: "center" };
        const conStyle = { marginBottom: "15px", padding: "20px" };
        const rowStyle = { marginTop: "50px" }
        const imageStyle = { maxHeight: "300px", marginLeft: "auto", marginRight: "auto", display: "block", minHeight : "100px" };


        const sunEditorPaddingSmall = { padding: "30px", paddingTop: "0px" }
        const sunEditorPaddingLarge = { padding: "30px", paddingTop: "40px" }
        return (
            <Container fluid style={conStyle}>
                <Title name="About Me" />
                <Row style={rowStyle}>
                    <Col xs={12} md={3}>
                        <Image style={imageStyle} src={profileYogaPic} rounded fluid className="shadow-sm" />
                    </Col>
                    <Media queries={{ small: { maxWidth: 767 } }}>
                        {matches =>
                            matches.small ? (
                                <Col xs={12} md={9} style={sunEditorPaddingLarge}>
                                    <SunEditorComp type={1} />
                                </Col>
                            ) : (
                                    <Col xs={12} md={9} style={sunEditorPaddingSmall}>
                                        <SunEditorComp type={1} />
                                    </Col>
                                )
                        }
                    </Media>

                </Row>

            </Container>
        )
    }
}



export default AboutSection
