import React from 'react'

//css
import './staticbanner.css'

const StaticBanner = () => {
    return (
        <div className="bannerBackground">
            <div id="backgroundText">
                <h1 id="yogaText">Welcome to Yoga With Pazzy</h1>
            </div>
        </div>
    )
}

export default StaticBanner
