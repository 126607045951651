import React from 'react'

import Alert from 'react-bootstrap/Alert';

const feedbackComponent = (props) => {
    const feedbackContent = props.content;
    const yogaClassesType = props.yogaClassesType;
    const aboutMeType = props.aboutMeType;
    let showAlert = false;
    if (feedbackContent === "About Me Text edited successfully" && aboutMeType) {
        showAlert = true;
    }
    else if (feedbackContent === "Yoga Classes Text edited successfully" && yogaClassesType) {
        showAlert = true;
    }
    return (
        <div>
            {showAlert ? <Alert variant="success">{feedbackContent}</Alert> : undefined}
        </div>
    )
}

export default feedbackComponent
