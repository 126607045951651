import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './App.css';

//pages
import home from './pages/home';
import login from './pages/login';

import jwtDecode from 'jwt-decode'

//redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser } from './redux/actions/userActions';
import axios from 'axios';

import DocumentMeta from 'react-document-meta';

axios.defaults.baseURL = "https://europe-west1-yogawithpazzy-1ccdc.cloudfunctions.net/api"

function App() {
  const meta = {
    title: 'Yoga With Pazzy',
    description: 'Welcome to YogaWithPazzy. Milton Keynes based yoga, join online classes now!!',
    canonical: 'http://yogawithpazzy.co.uk',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags, yoga, pazzy, sunset, yogawithpazzy, changeyourlife'
      }
    }
  };
  store.dispatch(logoutUser());
  const token = localStorage.FBIdToken;
  // if (token) {
  //   const decodedToken = jwtDecode(token);
  //   if (decodedToken.exp * 1000 < Date.now()) {//token expired
  //     store.dispatch(logoutUser());
  //     window.location.href = "/login"
  //   }
  //   else {
  //     store.dispatch({ type: SET_AUTHENTICATED });
  //   }
  // }
  // else {
  //   store.dispatch(logoutUser());
  // }
  return (
    <DocumentMeta {...meta}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={home} />
            <Route exact path="/login" component={login} />
          </Switch>
        </Router>
      </Provider>
    </DocumentMeta>

  );
}

export default App;
