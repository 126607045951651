import React, { Component } from 'react'


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Title from '../components/Title'

import SunEditorComp from '../components/SunEditorComponent'

export class YogaClassSection extends Component {
    constructor() {
        super();
    }

    render() {

        const conStyle = { marginBottom: "15px" };
        const rowStyle = { paddingTop: "15px", padding: "20px" }
        return (
            <Container fluid style={conStyle} >
                <Title name="Yoga Classes" />
                <Row style={rowStyle}>
                    <Col xs={12}>
                        <SunEditorComp type={2}/>
                    </Col>
                </Row>
            </Container>
        )
    }



}



export default YogaClassSection
