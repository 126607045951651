import React, { Component } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

//navbar imports
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//redux
import { logoutUser } from '../redux/actions/userActions'
import { connect } from 'react-redux';
//images
import Logo from '../pics/blacklogostatusbar.png';
//media sizing
import Media from 'react-media';
//css
import './component_css/navbar.css'

export class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullOffset: 0,
            fullDuration: 400,
            expanded: false,
            navStyle: { backgroundColor: "transparent", position: "fixed", transition: "0.5", margin: "15px 20px 15px 20px" },
            variant: "dark",
            logButtonStyle: { textDecoration: 'inherit', color: "inherit" },
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated) {
            this.setState({ authenticated: nextProps.authenticated });
        }
    }


    setExpanded = () => {
        this.setState({ expanded: this.state.expanded ? false : "expanded" })

        if (this.state.expanded === false) {
            this.setState({ navStyle: { backgroundColor: "#EFEEEE", position: "fixed", transition: "0.5s", margin: "20px 15px 20px 15px", borderRadius: "2px" }, variant: "light" })

        }
        else {
            this.setState({ navStyle: { backgroundColor: "transparent", position: "fixed", transition: "0.5s", margin: "15px 20px 15px 20px" }, variant: "dark" })

        }
    }



    render() {

        const authenticated = this.props.authenticated;
        const logoutUser = this.props.logoutUser;
        return (
            <Navbar style={this.state.navStyle} variant={this.state.expanded ? this.state.variant : (this.props.below ? "light" : "dark")} fixed="top" sticky="top" className="justify-content-between" expand="md" expanded={this.state.expanded}>
                <Navbar.Brand>
                    {/* <p className="lead"><em>Yoga with Pazzy</em></p>

                     */}
                    

                    <Media queries={{ small: { maxWidth: 1225 } }}>
                        {matches =>
                            matches.small ? (
                                <img src={Logo} class="rounded" style={{ maxHeight: "70px", backgroundColor : "#EFEEEE", padding : "6px" }} />
                            ) : (
                                <img src={Logo} style={{ maxHeight: "80px" }} />
                                )
                        }
                    </Media>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="fullnavbar" onClick={this.setExpanded} />
                <Navbar.Collapse id="fullnavbar" className="justify-content-end">
                    <Nav className="mr-auto" className="justify-content-end">

                        <ScrollLink activeClass="active"
                            to="homeSection"
                            spy={true}
                            smooth={true}
                            offset={this.state.fullOffset}
                            duration={this.state.fullDuration}><Nav.Link><p className="blockquote" id="links">Home</p></Nav.Link></ScrollLink>
                        <ScrollLink activeClass="active"
                            to="aboutSection"
                            spy={true}
                            smooth={true}
                            offset={this.state.fullOffset}
                            duration={this.state.fullDuration}><Nav.Link><p className="blockquote" id="links">About</p></Nav.Link></ScrollLink>
                        <ScrollLink activeClass="active"
                            to="yogaSection"
                            spy={true}
                            smooth={true}
                            offset={this.state.fullOffset}
                            duration={this.state.fullDuration}><Nav.Link><p className="blockquote" id="links">Yoga Classes</p></Nav.Link></ScrollLink>
                        <ScrollLink activeClass="active"
                            to="contactSection"
                            spy={true}
                            smooth={true}
                            offset={this.state.fullOffset}
                            duration={this.state.fullDuration}><Nav.Link><p className="blockquote" id="links">Contact</p></Nav.Link></ScrollLink>

                        {authenticated ? <Nav.Link onClick={logoutUser}><p className="blockquote" id="links" style={this.state.logButtonStyle}><em>Logout</em></p></Nav.Link> : <Nav.Link><p className="blockquote"><em><Link to="/login" style={this.state.logButtonStyle}>Login</Link></em></p></Nav.Link>}


                    </Nav>

                </Navbar.Collapse>

            </Navbar>
        )
    }



}


const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
})

const mapActionsToProps = {
    logoutUser
}

export default connect(mapStateToProps, mapActionsToProps)(NavBar);
