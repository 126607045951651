import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Title from '../../src/components/Title'

export class FooterSection extends Component {
    render() {


        return (
            <Container fluid style={{overflow: "none"}}>
                <Row>
                    <Col xs={4}>
                        <p className="lead" style={{ marginTop: "40px", textAlign: "center", fontSize: "1rem" }}><em>Website Made by Rajen Parekh</em></p>
                        <p className="lead" style={{ marginTop: "-10px", textAlign: "center", fontSize: ".7rem"}}><em>rajenparekh2000@gmail.com</em></p>
                    </Col>
                    <Col xs={4}>
                        <p className="lead" style={{ marginTop: "40px", textAlign: "center", fontSize: "1rem" }}><em>Logo By InspiredLogos</em></p>
                        <p className="lead" style={{ marginTop: "-10px", textAlign: "center", fontSize: ".7rem"}}><em><a  href="https://www.theinspired.group/inspired-logos/">www.inspiredlogos.co.uk</a></em></p>
                    </Col>
                    <Col xs={4} >
                        <p className="lead" style={{marginTop: "40px", textAlign: "center", fontSize: "1rem"}}><a href="https://www.termsandconditionsgenerator.com/live.php?token=F5GQc3UcC2v3QIq9MPCnv0pUYcgEASO2">Terms and Conditions</a></p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default FooterSection
