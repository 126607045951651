import React from 'react'

const Title = (props) => {
    const h1Style= {color: "#424242", marginTop: "60px"}

    return (
        <h1 style={h1Style}>
            {props.name}
        </h1>
    )
}

export default Title
