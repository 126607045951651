import { SET_USER, SET_ERRORS, CLEAR_ERRORS, LOADING_UI, SET_UNAUTHENTICATED, SET_AUTHENTICATED, POST_ABOUTMETEXT, POST_YOGACLASSESTEXT, GET_ABOUTMETEXT, GET_YOGACLASSESTEXT, STOP_LOADING_UI } from '../types';
import axios from 'axios';

export const postAboutMeText = (newContent) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios.post('/aboutMeText', { "text": newContent })
        .then(res => {
            dispatch({
                type: POST_ABOUTMETEXT,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
            // const resText = res.data.message;
            // console.log(resText);
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}

export const postYogaClassesText = (newContent) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    axios.post('/yogaClassesText', { "text": newContent })
        .then(res => {
            // if(localStorage.getItem("FBIdToken"))
            // {
            //     axios.defaults.headers.common["Authorization"] = localStorage.getItem("FBIdToken");
            // }
            dispatch({
                type: POST_YOGACLASSESTEXT,
                payload: res.data
            });
            dispatch({ type: CLEAR_ERRORS });
            // const resText = res.data.message;
            // console.log(resText);
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });
}



// export const getAboutMeText = () => (dispatch) => {
//     dispatch({ type: LOADING_UI });
//     axios.get("/aboutMeText")
//         .then(res => {
//             console.log(res.data.text);
//             dispatch({
//                 type: GET_ABOUTMETEXT,
//                 payload: res.data.text
//             });
//             dispatch({ type: CLEAR_ERRORS });
//         })
//         .catch(err => {
//             dispatch({
//                 type: SET_ERRORS,
//                 payload: err.response.data
//             })
//         });//TO DO : SET ERRORS
// }

export const getAboutMeText = (cancelparam) => (dispatch) => {//get about me text with cancel param
    dispatch({ type: LOADING_UI });
    axios.get("/aboutMeText", {
        cancelToken: new axios.CancelToken(function executor(c) {
            cancelparam.cancel = c
        })
    })
        .then(res => {
            dispatch({
                type: GET_ABOUTMETEXT,
                payload: res.data.text
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });//TO DO : SET ERRORS
}


// export const getYogaClassesText = () => (dispatch) => {
//     dispatch({ type: LOADING_UI });
//     axios.get("/yogaClassesText")
//         .then(res => {
//             dispatch({
//                 type: GET_YOGACLASSESTEXT,
//                 payload: res.data.text
//             });
//             dispatch({ type: CLEAR_ERRORS });
//         })
//         .catch(err => {
//             dispatch({ type: SET_ERRORS,
//              payload: err.response.data })
//         });//TO DO : SET ERRORS
// }


export const getYogaClassesText = (cancelparam) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.get("/yogaClassesText",
        {
            cancelToken: new axios.CancelToken(function executor(c) {
                cancelparam.cancel = c
            })
        })
        .then(res => {
            dispatch({
                type: GET_YOGACLASSESTEXT,
                payload: res.data.text
            });
            dispatch({ type: CLEAR_ERRORS });
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        });//TO DO : SET ERRORS
}