import React, { Component } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner'

import AlertComponent from '../components/AlertComponent'
import LoginComponent from '../components/LoginComponent'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import axios from 'axios';

import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions'

export class login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            errors: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

  
    


    handleSubmit = (event) => {
        event.preventDefault();

        const userData = {
            email: this.state.email[0],
            password: this.state.password[0]
        }

        if(this.state.email[0] === undefined || this.state.password[0] === undefined )
        {
            this.setState({
                errors : {"error" : "empty"}
            })
            return
        }

        this.props.loginUser(userData, this.props.history)


    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: [event.target.value]
        })
    }

    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    render() {
        //
        const { UI: { loading } } = this.props;
        const { errors } = this.state;
        return (
            <div>
                <Container fluid style={{ height: "100vh" }}>
                    <Row style={{ position: "absolute", top: "20%", width: "100%" }}>
                        <Col ></Col>
                        <Col xs={12} xl={5} style={{ textAlign: "center" }}>
                            <h3 style={{ color: "#424242", marginBottom: "60px" }}>Yoga With Pazzy Login</h3>
                            <Card className="shadow-sm">
                                <Card.Body>
                                    <Form noValidate validated={!this.isEmpty(this.state.errors)} onSubmit={this.handleSubmit}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">
                                                Email
                                            </Form.Label>
                                            <Col sm="6">
                                                <Form.Control required id="email" name="email" type="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </Col>
                                            <Col sm="3">
                                                <Button variant="outline-success" type="submit" >{loading ? <Spinner
                                                    style={{ "margin-right": "7px" }}
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> : undefined}Login</Button>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Form.Label column sm="3">
                                                Password
                                            </Form.Label>
                                            <Col sm="6">
                                                <Form.Control required id="password" name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>

                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                            </Card>
                            {errors.general && (<AlertComponent content={errors.general} variant="danger" />)}
                            {errors.error ? (errors.error === "auth/user-not-found" ? <AlertComponent content={"Email not found"} variant="danger" /> : undefined) : undefined}
                            {errors.error ? (errors.error === "auth/invalid-email"  ? <AlertComponent content={"Please enter a valid Email"} variant="danger" /> : undefined) : undefined}
                            {errors.error ? (errors.error === "empty"  ? <AlertComponent content={"Please enter some data"} variant="danger" /> : undefined) : undefined}
                        </Col>
                        <Col ></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
});

const mapActionsToProps = {
    loginUser
}

export default connect(mapStateToProps, mapActionsToProps)(login)
